import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateUpgradeInput } from '__generated__/graphql';
import { Ticket, getVisibleProductsForTicket } from '../helpers';
import { calculateQuantities, calculateUpgradeAvailability } from '../useQuantities';
import { useEvent } from '../EventProvider';
import CheckoutContext from '../CheckoutContext';
import ProductSelector from '../Personalisation/ProductSelector';
import UI from '../../../common/UI';

export interface BusinessProductSelectorProps {
  ticket: Ticket;
}

const BusinessProductSelector = ({ ticket }: BusinessProductSelectorProps) => {
  const { t } = useTranslation();
  const { event } = useEvent();

  const {
    form,
    businessProducts,
    setBusinessRegistrations,
    quantities,
    availability,
  } = useContext(CheckoutContext);

  /**
   * The products that can be chosen with the current ticket.
   * Product variants are removed, because they are chosen when claiming a ticket.
   */
  const products = getVisibleProductsForTicket(ticket, businessProducts).map((product) => ({
    ...product,
    active_product_variants: [],
  }));

  /** All of the current ticket's promotion IDs */
  const ticketPromotionIds = useMemo(() => ticket.promotions_for_sale.map(({ id }) => id), [ticket]);

  /**
   * Sets the upgrades on all business registrations with the current ticket.
   */
  const handleProductsChange = useCallback(
    (upgrades: CreateUpgradeInput[]) => {
      setBusinessRegistrations(
        (registrations) => registrations.map((registration) => {
          if (ticketPromotionIds.includes(registration.promotion.id)) {
            return {
              ...registration,
              upgrades,
            };
          }

          return registration;
        }),
        event,
      );
    },
    [setBusinessRegistrations, ticketPromotionIds, event],
  );

  /** All business registrations with the same ticket. */
  const businessRegistrations = form.registrations.business.filter((registration) => (
    ticketPromotionIds.includes(registration.promotion.id)
  ));

  /** The selected quantities of this ticket's upgrades. */
  const upgradeQuantities = calculateQuantities({
    businessRegistrations,
    ticketCategories: event.ticket_categories,
    products,
  });

  /** The total quantity for the current ticket. */
  const ticketQuantity = businessRegistrations.reduce((quantity, registration) => (
    quantity + registration.quantity
  ), 0);

  /** The units in which upgrades are bought. */
  const units = ticketQuantity * ticket.units;

  /** The remaining availability of this ticket's products.  */
  const upgradeAvailability = calculateUpgradeAvailability({
    quantities: upgradeQuantities,
    availability,
    products,
    units,
  });

  return (
    <UI.FormGrid>
      <UI.Legend>
        {t('extras_form.extras_for')}
        {' '}
        <UI.Span style={{ fontWeight: 500 }}>
          <UI.Icon>
            <UI.Icons.Ticket />
          </UI.Icon>
          {' '}
          {ticket.title}
        </UI.Span>
      </UI.Legend>
      <ProductSelector
        event={event}
        products={products}
        upgrades={businessRegistrations[0].upgrades}
        onChange={handleProductsChange}
        quantities={upgradeQuantities}
        globalQuantities={quantities}
        availability={upgradeAvailability}
        units={units}
        showVat
      />
    </UI.FormGrid>
  );
};

export default BusinessProductSelector;
